.tinted-cards ul {
 padding-left: 0;
 display: grid;
 gap: .75rem;
 grid-auto-rows: minmax(9rem, auto);
}

@include media-breakpoint-up(sm) {
 .tinted-cards ul {
 grid-template-columns: repeat(2, 1fr);
 }
}

@include media-breakpoint-up(lg) {
 .tinted-cards-fill-columns ul {
 grid-auto-flow: column;
 }
 
 .tinted-cards {
 ul {
 grid-template-columns: repeat(3, 1fr);
 }
 
 li:has(.medium) {
 grid-row-end: span 2;
 }

 li:has(.large) {
 grid-row-end: span 3;
 }
 }
}

.tinted-cards li {
 display: flex;
 flex-direction: column;
 justify-content: flex-end;
 margin: 0 .15rem;
 padding: 16px;
 position: relative;
 transition: .2s;
 min-height: 8rem;
 border-radius: $border-radius;
 break-inside: avoid;
 page-break-inside: avoid;
 
 .nav-text, .msl-nav-description, .msl-imagenav-title, .msl-imagenav-description {
 width: fit-content;
 padding: 6px 12px;
 background-color: #fff;
 border-radius: $border-radius;
 display: block;
 }
 
 .nav-text, .msl-imagenav-title {
 font-size: 2rem;
 font-weight: bold;
 padding-right: 2.2rem; // to capture the right arrow in the white background
 }
 
 .msl-nav-description, .msl-imagenav-description {
 margin-bottom: 0;
 margin-top: -13px;
 border-top-left-radius: 0;
 }
 
 img {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 object-fit: cover;
 z-index: -2;
 border-radius: $border-radius;
 }
 
 a::after {
 // Colour overlaid on the image
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: var(--bs-primary-400);
 opacity: 0.5;
 border-radius: $border-radius;
 
 z-index: -1;
 }
 
 a {
 text-decoration: none;
 color: $body-color;
 }
 
 .nav-text::after, .msl-imagenav-title::after {
 content: "";
 display: inline-block;
 background: var(--arrow-right);
 background-repeat: no-repeat;
 background-position: center bottom;
 background-size: 1.3rem;
 width: 1.6rem;
 height: 2rem;
 margin-left: 0.3rem;
 margin-right: -1.6rem; // to prevent line breaks and leaving the arrow by itself
 }
 
 a::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 1;
 }
 
 &:hover {
 transform: scale(101%);
 }
 
 &:active {
 transform: scale(99%);
 }
 
 .crimson::after { 
 background-color: var(--bs-crimson-400);
 }

 .blue::after {
 background-color: var(--bs-blue-400);
 }

 .yellow::after {
 background-color: var(--bs-yellow-400);
 }

 .gray::after {
 background-color: var(--bs-gray-400);
 }
}

