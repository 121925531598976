table {
 width: fit-content;
 max-width: 100%;
 border-radius: $border-radius;
 margin-bottom: 1rem;
 
 display: block;
 overflow-x: auto;
 white-space: nowrap;
 
 thead, th {
 background-color: var(--bs-primary-700);
 font-weight: bold;
 color: #F3F3F3;
 
 td {
 color: #F3F3F3;
 }
 }
 
 td, th {
 padding: 8px 10px;
 border-right: .5px solid var(--bs-primary);
 
 &:last-child {
 border-right: 0;
 }
 }
 
 tr {
 border-bottom: .5px solid var(--bs-primary);
 
 &:nth-child(even) {
 background-color: var(--bs-primary-100);
 }
 
 &:last-child {
 border-bottom: 0;
 }
 }
}

// Undo styling on the PDF viewer navbar
.MenuBarBkGnd td {
 padding: 0 5px !important;
 border-right: 0px !important;
}

.MSRS-RVC .ToolbarFind, .MSRS-RVC .ToolbarZoom {
 padding-top: 0px !important;
}