/*!
 * Your Union Design System features code from
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "patterns";
@import "fonts";
@import "typography";

@import "bootstrap/functions";

@import "colors";
@import "icons";

$border-radius: 10px;

@import "bootstrap/utilities";
@import "bootstrap/utilities/api";

@import "bootstrap/root";
@import "bootstrap/reboot";

@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/type";
@import "bootstrap/helpers";

ol, ul {
 padding-left: 1.5rem;
}

@import "accordion";
@import "button";
@import "core";
@import "event-row";
@import "form";
@import "footer";
@import "header";
@import "heading";
@import "headline";
@import "inset";
@import "msl-icons";
@import "notification";
@import "profile-list";
@import "society";
@import "table";
@import "tinted-cards";

@import "print";