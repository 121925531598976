@media print { 
 .main-navigation, 
 .page-heading::before,
 .page-heading::after,
 .page-heading-decor,
 .page-heading-image,
 .skip-link,
 footer .container,
 #breadcrumb {
 display: none !important;
 }
 
 header {
 margin-bottom: 0 !important;
 }
 
 header .logo img {
 margin-left: 0 !important;
 }
 
 .page-heading {
 margin-bottom: 1rem !important;
 min-height: min-content;
 }

 .page-heading-title {
 color: $body-color !important;
 font-size: $h1-font-size !important;
 margin: 0 !important;
 padding: 1rem !important;
 line-height: initial !important;
 }

 .footer-ad {
 visibility: hidden !important;
 }
 
 h2, h3, h3, h4, h5, h6,
 .h2, .h3, .h3, .h4, .h5, .h6 {
 &:not(.plain) {
 padding-bottom: 0 !important;
 
 &::before {
 content: none !important;
 }
 }
 }
}