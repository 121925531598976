.headline { 
 .msl-item-list {
 display: grid;
 align-items: stretch;
 justify-items: center;
 gap: .75rem;
 grid-template-columns: repeat(2, 1fr);
 }
 
 @include media-breakpoint-up(sm) {
 .msl-item-list {
 grid-template-columns: repeat(3, 1fr);
 }
 }
 
 .msl-item:first-child {
 // The first news item should be large
 grid-column-end: span 2;
 grid-row-end: span 2;
 
 &:nth-last-child(1) {
 // If this news item is both the first AND last, that means it's the 
 // only news article, and should be larger still
 grid-column-end: span 3;
 }
 }
 
 .msl-item {
 max-width: 1200px;
 width: 100%;
 height: 100%;
 margin: 0 !important;
 }
 
 .msl-image {
 display: inline;
 margin: 0 !important;
 width: 100%;
 height: 100%;
 
 img {
 width: 100%;
 height: 100%;
 height: inherit;
 object-fit: cover;
 }
 }

 .msl-title, .msl-leader, .msl-item-details {
 display: none;
 }
}