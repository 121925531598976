.soc-intro {
 @include font-size(2rem);
}

.join_list {
 margin: 0 !important;
 padding: 0;
 
 li {
 display: flex;
 align-items: center;
 justify-content: space-between;
 flex-direction: row;
 }
 
 .product-price {
 font-weight: bold;
 margin-left: 1.2rem !important;
 }
}

.interestWidget {
 display: grid;
 grid-template-columns: 1fr min-content;
 align-items: end;
 
 table {
 margin-bottom: 0 !important;
 }
 
 td {
 display: flex;
 align-items: center;
 padding-left: 0;
 }
 
 label {
 display: flex !important;
 }
 
 .msl_notification {
 grid-column: span 2;
 }
}

.msl_signup {
 margin: 0 !important;
 border-color: var(--bs-primary-100) !important;
 
 dt {
 background-color: var(--bs-primary-100) !important;
 }
 
 .msl_signup_date {
 margin: 0 !important;
 }
 
 .msl_signup_places {
 margin: 0 !important;
 padding-top: 0 !important;
 }
 
 .msl_signup_controls {
 background-color: transparent !important;
 }
}

.msl-news .msl-item {
 clear: both;
 margin-bottom: 1rem;
 
 .msl-image {
 float: left;
 margin-right: 1rem;
 margin-bottom: 1rem;
 }
 
 .msl-title {
 position: static;
 padding-bottom: 0;

 &::before {
 content: none;
 }
 }
}