.footer-skyline {
 display: flex;
 gap: 1rem;
 position: relative;
 bottom: -1px;
 
 margin-top: 1rem;
 
 justify-content: space-between;
 align-items: flex-end;
 
 color: var(--bs-primary-500);
 
 .footer-ad {
 margin: 1rem 0;
 
 img {
 max-height: 80px;
 }
 
 & a::before {
 content: "Advert";
 color: $body-color;
 font-size: .7rem;
 display: block;
 text-decoration: none;
 }
 }
 
 svg {
 max-height: 80px;
 width: 100%;
 }
}

@include media-breakpoint-down(sm) { 
 .footer-skyline {
 flex-direction: column;
 }
}

footer {
 border-top: 2px solid var(--bs-primary);
 padding: 1rem 0;
 font-size: 0.8rem;
 
 h2 {
 font-size: 1.2rem;
 }
 
 li {
 margin-bottom: 0.4rem;
 
 img, svg { // Social icons
 height: .9rem;
 width: .9rem;
 margin-right: .5rem;
 }
 }
 
 svg {
 color: var(--bs-primary);
 }
 
 address {
 display: inline-block;
 vertical-align: top;
 }
}