.event-row {
 position: relative;
 
 .msl_eventlist div {
 display: flex;
 justify-content: space-evenly;
 flex-wrap: wrap;
 }
 
 &::before {
 // Spotty rectangle
 content: "";
 position: absolute;
 top: -2%;
 right: 0;
 width: 60%;
 height: 100%;
 opacity: 0.1;
 z-index: -1;

 @include bg-spot;
 background-color: var(--bs-blue);
 }
 
 .event_item {
 display: inline-block;
 position: relative;
 text-align: center;
 vertical-align: text-top;
 margin-right: 13px;
 margin-bottom: 15px;
 width: 100px;
 transition: .2s;
 
 a {
 color: $body-color;
 }
 
 a::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 1;
 }
 
 &:hover {
 transform: scale(102%);
 }
 
 &:active {
 transform: scale(98%);
 }
 }
 
 .msl_event_organisation {
 font-weight: 400;
 font-size: 0.8rem;
 display: block;
 }

 .msl_eventlist img {
 display: block;
 margin-bottom: 5px;
 }

 .msl_event_time {
 margin-inline-start: 0;
 font-size: 0.75rem;
 }

 .msl_event_description, .msl_event_location, .msl_event_types {
 display: none
 }
}