// MSL Organisation Lists have one <li> with the org image, followed by a second <li> with
// title and description. Use a bespoke column layout to make that appear nicely.
.profile-list .msl_organisation_list {
 grid-template-columns: repeat(1, 100px 1fr);
}

@include media-breakpoint-up(sm) {
 .profile-list .msl_organisation_list {
 grid-template-columns: repeat(2, 100px 1fr);
 }
}

@include media-breakpoint-up(lg) {
 .profile-list .msl_organisation_list {
 grid-template-columns: repeat(3, 100px 1fr);
 }
}

.profile-list ul {
 list-style: none;
 padding-inline-start: 0;
 margin: 0 !important;
 
 display: grid;
 gap: 1rem;
 // capital 'M' in the 'Min()' function on the next line means that the min rule
 // is processed by CSS, not Sass. This means that the columns of the grid will 
 // never overflow the viewport. Sass' min function throws an error, since it can't
 // know the % of screen size at compile time.
 // Capital M trick; https://stackoverflow.com/a/65008379
 grid-template-columns: repeat(auto-fit, minmax(Min(350px, 100%), 1fr));
 
 li {
 margin: 0 !important;
 position: relative;
 }
 
 .msl-imagenav-image {
 grid-row: 1/-1;
 }
 
 img {
 border-radius: $border-radius;
 }
 
 .msl-listingitem-link, .msl-imagenav-title {
 // Item names and titles
 font-weight: bold;
 text-decoration: underline;
 font-size: 1.2rem;
 grid-column: 2;
 }
 
 .msl-listingitem-description, .msl-imagenav-description {
 // Trim descriptions to 3 lines
 overflow: hidden;
 -webkit-box-orient: vertical;
 display: block;
 display: -webkit-box;
 overflow: hidden !important;
 text-overflow: ellipsis;
 -webkit-line-clamp: 3;
 color: var(--bs-body-color);
 grid-column: 2;
 }
 
 .msl-listing-category {
 // Grouping listing category headings
 grid-column: 1/-1;
 
 h3 {
 margin: 0 !important;
 border: none !important;
 }
 }
 
 .msl-listingitem-link::before {
 // Stretch group listing link
 content: "";
 position: absolute;
 inset: 0;
 }
 
 .msl-imagenav-page {
 // Link element which wraps the entire image navigation element
 display: grid;
 grid-template-columns: 100px 1fr;
 grid-template-rows: min-content 1fr;
 column-gap: 1rem;
 
 // This prevents the description being underlined, and we separately underline
 // the title element
 text-decoration: none;
 }
}
