$font-family-sans-serif: Gotham, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-lighter: lighter;
$font-weight-light: lighter;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-bolder: bold;

$headings-font-weight: $font-weight-bold;

img { 
 // Prevent any overly-large user-uploaded images overflowing the viewport
 max-width: 100%;
 object-fit: contain;
}

h2, h3, h3, h4, h5, h6,
.h2, .h3, .h3, .h4, .h5, .h6 {
 
 &:not(.plain):not(.msl-title) {
 background-color: var(--bs-primary-100);
 width: fit-content;
 padding: 10px;
 }
}

li::marker {
 color: var(--bs-primary);
}

.inline-list {
 list-style: none;
 padding-inline-start: 0;
 
 li {
 display: inline;
 white-space: nowrap;
 margin-right: 1rem;
 }
}

.arrow-after::after {
 content: " \2192";
}