@mixin bg-stripe {
 -webkit-mask-image: repeating-linear-gradient(
 315deg,
 transparent,
 transparent 15px,
 black 15px,
 black 18px
 );
}

@mixin bg-spot {
 -webkit-mask-image: radial-gradient(circle, 
 black 25%, 
 transparent 25%);
 -webkit-mask-repeat: round;
 -webkit-mask-size: 30px 30px;
}

.bg-stripe {
 @include bg-stripe;
}

.bg-spot {
 @include bg-spot;
}