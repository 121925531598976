/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 21, 2022 */

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothamblack.woff2') format('woff2'),
 url('/assets/fonts/gothamblack.woff') format('woff');
 font-weight: 900;
 font-style: normal;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothamblackitalic.woff2') format('woff2'),
 url('/assets/fonts/gothamblackitalic.woff') format('woff');
 font-weight: 900;
 font-style: italic;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothambold.woff2') format('woff2'),
 url('/assets/fonts/gothambold.woff') format('woff');
 font-weight: 700;
 font-style: normal;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothambolditalic.woff2') format('woff2'),
 url('/assets/fonts/gothambolditalic.woff') format('woff');
 font-weight: 700;
 font-style: italic;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothambook.woff2') format('woff2'),
 url('/assets/fonts/gothambook.woff') format('woff');
 font-weight: 400;
 font-style: normal;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothambookitalic.woff2') format('woff2'),
 url('/assets/fonts/gothambookitalic.woff') format('woff');
 font-weight: 400;
 font-style: italic;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothamlight.woff2') format('woff2'),
 url('/assets/fonts/gothamlight.woff') format('woff');
 font-weight: 100;
 font-style: normal;
 font-display: fallback;
}

@font-face {
 font-family: 'Gotham';
 src: url('/assets/fonts/gothamlightitalic.woff2') format('woff2'),
 url('/assets/fonts/gothamlightitalic.woff') format('woff');
 font-weight: 100;
 font-style: italic;
 font-display: fallback;
}