:root {
 --check: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#{$body-bg}'><path d='m11.941 28.877-11.941-11.942 5.695-5.695 6.246 6.246 14.364-14.363 5.695 5.695z'/></svg>"))};
 --green-check: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='#{$green}'><path d='m11.941 28.877-11.941-11.942 5.695-5.695 6.246 6.246 14.364-14.363 5.695 5.695z'/></svg>"))};
 --warning: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 490 490' fill='#{$red}'><path d='m245 0c-135.316 0-245 109.684-245 245s109.684 245 245 245 245-109.684 245-245-109.684-245-245-245zm0 459.375c-118.213 0-214.375-96.163-214.375-214.375s96.162-214.375 214.375-214.375 214.375 96.162 214.375 214.375-96.163 214.375-214.375 214.375z'/><path d='m266.836 286.987 8.36-172.113h-60.408l8.744 172.113z'/><path d='m245.184 305.974c-20.136 0-34.178 14.424-34.178 34.576 0 19.738 13.674 34.576 34.178 34.576 20.503 0 33.825-14.823 33.825-34.576-.398-20.151-13.705-34.576-33.825-34.576z'/></svg>"))};
 --red-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$red}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --green-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$green}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --blue-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$blue}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --yellow-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$yellow}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --crimson-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$crimson}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --gray-dropdown: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$gray}' stroke-width='2' d='M2 5l6 6 6-6'/></svg>"))};
 --arrow-right: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path d='M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z'/></svg>"))};;
}