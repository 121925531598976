.msl_notification {
 background-color: transparent;
 font-weight: inherit;
 border: 1px solid var(--bs-primary);
 padding: 10px 16px;
 border-radius: $border-radius;
 
 .msl_info, .confirm, .msl_warning, .msl_error, .error {
 background-repeat: no-repeat;
 background-position: left center;
 background-size: 0.9rem;
 padding-left: 1.5rem;
 color: inherit;
 background-color: transparent;
 border: 0;
 }
 
 .msl_info, .confirm {
 background-image: var(--green-check);
 }
 
 .msl_warning, .msl_error, .error {
 background-image: var(--warning);
 }
}