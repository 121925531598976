@mixin nav-dropdown-container {
 display: flex;
 visibility: hidden;
 opacity: 0;
 transition: .6s all, .0s width;
 
 position: absolute;
 top: 100%;
 left: 0;
 
 width: 100%;
 padding: 0.8rem 1.5rem 1rem 1.5rem;
 background-color: var(--bs-primary-100);
 --header-border: var(--bs-primary);
 z-index: 999;
 
 text-align: center;
 align-items: center;
 justify-content: center;
 flex-wrap: wrap;
 vertical-align: center;
 
 h3 {
 display: none;
 }
 
 ul {
 display: inline;
 }
}

.skip-link {
 display: flex;
 align-items: center;
 height: 0;
 transition: .1s all;
 
 &:focus, &:focus-within {
 height: calc(1rem + 18px);
 }
}


header {
 border-top: 15px solid;
 border-color: var(--bs-primary);
 transition: .4s border-top, .1s .1s margin-bottom;
 position: relative;
 top: 0;
 left: 0;
 margin-bottom: 1rem;
 display: flex;
 justify-content: space-between;
 align-items: flex-end;
 flex-wrap: wrap;
 padding: 0 .6rem;
 
 .logo img {
 height: 3.9rem;
 width: initial;
 padding-bottom: 4px;
 margin-top: 15px;
 }
 
 @include media-breakpoint-up(md) {
 .logo img {
 height: 5.5rem;
 margin: 35px 45px 40px;
 }
 }
 
 nav {
 text-align: center;
 max-width: 100vw;
 
 ul {
 list-style: none;
 padding-inline-start: 0;
 margin: 0;
 width: 100%;
 min-width: min-content;
 display: flex;
 justify-content: flex-end;
 align-items: space-between;
 }
 
 li {
 display: inline-block;
 height: min-content;
 margin: 0 .1rem;
 
 &::before {
 content: none !important;
 display: none !important;
 }
 
 // SVGs are the icons for search, basket, etc
 svg {
 height: 1.2rem;
 width: 1.2rem;
 pointer-events: none; // Prevent JS click events targeting these instead of buttons
 }
 
 @include media-breakpoint-up(md) {
 svg {
 height: 1rem;
 width: 1rem;
 }
 }
 
 a, li {
 // Remove icons in MSL generated lists
 background: none !important;
 }
 
 a, button {
 color: $gray;
 display: inline-block;
 padding: 10px 10px !important;
 margin: 0 !important;
 border-bottom-left-radius: 0;
 border-bottom-right-radius: 0;
 
 &:hover {
 color: $gray-400;
 }
 
 &.current {
 font-weight: bold;
 text-decoration: none;
 }
 }
 }
 
 .nav-icon {
 display: inline-block;
 }
 
 #nav-dropdown-pages {
 .nav-dropdown-container {
 // Divider to split the first-level page section selection from the 
 // pages within that section.
 border-top: 1px solid var(--header-border);
 }
 
 .nav-dropdown-toggle {
 // Fully rounded buttons
 border-radius: $border-radius;
 }
 }
 
 @include media-breakpoint-up(lg) {
 // On large screens, show the entire page navigation structure
 // instead of hiding it in a hamburger
 #nav-dropdown-pages {
 visibility: visible;
 opacity: 1;
 display: inline;
 position: static;
 background: inherit;
 padding: inherit;
 width: fit-content;
 }
 
 #nav-dropdown-pages-toggle {
 position: absolute;
 visibility: hidden;
 }
 
 #nav-dropdown-pages {
 .nav-dropdown-container {
 // No divider needed
 border-top: 0;
 }
 
 .nav-dropdown-toggle {
 // Square off the button bottom to flow into the dropdown
 border-bottom-left-radius: 0;
 border-bottom-right-radius: 0;
 }
 }
 }
 
 .nav-dropdown-toggle {
 background: transparent;
 
 &[aria-expanded="true"] {
 background-color: var(--bs-primary-100);
 }
 
 // A version of this style with different selectors is in the noscript.css file
 // so that the dropdowns work without JavaScript
 &[aria-expanded="true"] + .nav-dropdown-container {
 visibility: visible;
 opacity: 1;
 transition: .2s all;
 }
 }
 
 .nav-dropdown-container {
 @include nav-dropdown-container;
 }
 
 // Style dropdown containers and the header (if the container is open)
 // to match the colour scheme.
 .gray + .nav-dropdown-container,
 .gray[aria-expanded="true"] {
 background-color: var(--bs-gray-100);
 --header-border: var(--bs-gray);
 }

 .blue + .nav-dropdown-container, 
 .blue[aria-expanded="true"] {
 background-color: var(--bs-blue-100);
 --header-border: var(--bs-blue);
 }

 .yellow + .nav-dropdown-container, 
 .yellow[aria-expanded="true"] {
 background-color: var(--bs-yellow-100);
 --header-border: var(--bs-yellow);
 }

 .crimson + .nav-dropdown-container, 
 .crimson[aria-expanded="true"] {
 background-color: var(--bs-crimson-100);
 --header-border: var(--bs-crimson);
 }
 
 // Style parent dropdown containers to match their open child -- i.e. under the 
 // page navigation hamburger menu, style the main section selector to match the
 // currently-open section dropdown. Defined separately to the above because :has
 // has limited browser support, and a CSS rule with one invalid selector discards
 // the entire rule.
 .nav-dropdown-container:has(.gray[aria-expanded="true"]) {
 background-color: var(--bs-gray-100);
 --header-border: var(--bs-gray);
 }

 .nav-dropdown-container:has(.blue[aria-expanded="true"]) {
 background-color: var(--bs-blue-100);
 --header-border: var(--bs-blue);
 }

 .nav-dropdown-container:has(.yellow[aria-expanded="true"]) {
 background-color: var(--bs-yellow-100);
 --header-border: var(--bs-yellow);
 }

 .nav-dropdown-container:has(.crimson[aria-expanded="true"]) {
 background-color: var(--bs-crimson-100);
 --header-border: var(--bs-crimson);
 }
 
 #search {
 div {
 width: 100%;
 }
 
 #ctl00_search_pnlSearch {
 display: flex;
 justify-content: space-between;
 gap: 10px;
 }
 
 input {
 display: inline-block;
 margin: 0;
 }
 
 button {
 display: inline-block;
 width: fit-content;
 }
 }
 
 #nav-dropdown-toggle-basket {
 position: relative;
 }
 
 #msl-basket-summary {
 visibility: hidden;
 position: absolute;
 padding: 0;
 top: 0;
 left: 50%;
 
 .msl-basket-link {
 display: none;
 }
 
 .msl-basket-itemcount {
 visibility: visible;
 pointer-events: none; // Prevent JS click events targeting these instead of buttons
 border: 2px solid white;
 background-color: var(--bs-primary-100);
 border-radius: 10rem;
 padding: 1px 5px;
 min-width: 20px;
 text-align: center;
 font-size: 0.6rem;
 color: $gray;
 line-height: inherit;
 }
 
 .msl-basket-itemcount-text {
 @include visually-hidden;
 }
 }
 
 #nav-edit-item {
 // The site editor menu is hidden by default and displayed by JavaScript if MSL populates it with options.
 display: none;
 }
 
 #ulAdmin li {
 display: inline-block;
 border: 0;
 }
 }
}