// MSL have some icons they add using utility classes, e.g. for social media.
// Some link text which accompanies the icons makes sense independently, e.g. "Facebook."
// Sometimes more generic link text, like "@StAndrewsUnion" is preferable, so the icon is
// needed to aid in understanding the link. This file uses pseudo-elements to add 'alt text'
// to icons where this is the case.

.msl_web, .msl_email, .msl_facebook, .msl_instagram, .msl_twitter, .msl_youtube {
 padding: 0 25px !important;
}

.msl_email::before {
 content: "Email: ";
 @include visually-hidden();
}

.msl_instagram::before {
 content: "Instagram: ";
 @include visually-hidden();
}

.msl_twitter::before {
 content: "Twitter: ";
 @include visually-hidden();
}