input, input.textbox, select, textarea {
	display: block;
 font-size: inherit;
 width: 100%;
 margin: 6px 0 25px 0;
 padding: 7px 12px;
 border: 2px solid var(--bs-primary);
 border-radius: $border-radius;
 color: $body-color;
 background-color: $body-bg;
 
 &:focus {
 border: 2px solid var(--bs-primary);
 background-color: $body-bg;
 };
}

label, legend {
 display: flex;
 align-items: center;
 font-size: inherit;
 font-size: 1.05rem;
}

fieldset {
 margin-bottom: 25px;
}

input[type="radio"], input[type="checkbox"] {
 appearance: none;
 margin-right: 2.5rem;
 cursor: pointer;
 display: inline-block;
 height: 1.9rem;
 width: 1.9rem !important; // important because MSL system styles set it to `auto` or `15em`
 margin: .3rem .5rem .3rem 0;
 z-index: 0;
 border: 0;
 box-shadow: 0 0 0 2px var(--bs-primary);

 & + label {
 display: inline-block;
 }
 
 &:checked {
 background-color: var(--bs-primary);
 border: 3px solid $body-bg;
 box-shadow: 0 0 0 2px var(--bs-primary);
 }
}

input[type="radio"] { 
 border-radius: 10rem;
}

input[type="checkbox"] { 
 &:checked {
 background-image: var(--check);
 background-repeat: no-repeat;
 background-position: center;
 background-size: 1rem;
 }
}

input[type="file"] {
 border: none;
 padding: 0;
 
 // A different statement is required for each pseudoselector because one
 // invalid pseudoselector in a comma-separated list invalidates the whole
 // CSS block
 &::file-selector-button {
 @include button-styles;
 @include primary-buttons;
 }

 &::-webkit-file-selector-button {
 @include button-styles;
 @include primary-buttons;
 }

 &::-ms-browse {
 @include button-styles;
 @include primary-buttons;
 }
}

select {
 background-repeat: no-repeat;
 background-position: right .75rem center;
 background-size: 0.9rem;
 appearance: none;
 background-image: var(--gray-dropdown);

 .primary-blue &, .page_spaces &, .page_events &, .page_ents &, .page_root &, .page_homepage-old & {
 background-image: var(--blue-dropdown); 
 }

 .primary-yellow &, .page_representation &, .page_support & {
 background-image: var(--yellow-dropdown); 
 }

 .primary-crimson & {
 background-image: var(--crimson-dropdown);
 }
}

.validated {
 &:valid {
 border-color: $green;
 }

 &:invalid {
 border-color: $red;
 }
}

input.validated, textarea.validated {
 background-repeat: no-repeat;
 background-position: right .75rem bottom .75rem;
 background-size: 1rem;
 
 &:valid {
 background-image: var(--green-check);
 }

 &:invalid {
 background-image: var(--warning);
 }
}

select.validated {
 background-repeat: no-repeat;
 background-position: right 2.25rem center, right .75rem center;
 background-size: 1rem;
 
 &:valid {
 background-image: var(--green-check), var(--green-dropdown);
 }
 
 &:invalid {
 background-image: var(--warning), var(--red-dropdown);
 }
}

// Page editor
#edit_body {
 select {
 display: inline;
 width: inherit;
 padding-right: 3rem;
 }
}

.msl_admin_page {
 .msl-addnew {
 float: right !important;
 }
 
 .vp_form {
 .vpFormPair {
 width: 100%;
 }
 
 select {
 width: 95%;
 }
 
 .title {
 margin-top: .9rem;
 }
 
 input {
 margin: 6px 0 25px 0;
 padding: 7px 12px !important;
 }
 
 input.date_date {
 width: 8.25rem !important;
 border-right-width: 1px;
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 }
 
 input.date_time {
 width: 5.5rem !important;
 border-left-width: 1px;
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
 }
 }
}