// Explicit Z-indexes for this file:
// 1 - Image colour overlay
// 2 - Middle pattern decor elements
// 3 - Heading text

.page-heading {
 min-height: 12rem;
 margin-bottom: 4rem;
 position: relative;
 display: flex;
 align-content: flex-end;
 
 .page-heading-title {
 padding: 12rem 1rem 1rem 1rem;
 @include font-size(5rem);
 color: white;
 font-weight: 900;
 
 &.page-heading-title-content {
 z-index: 3;
 }
 }
 
 @include media-breakpoint-up(md) {
 .page-heading-title {
 padding-left: 2rem;
 }
 }
 

 .page-heading-image img {
 width: 100%;
 height: 100%;
 position: absolute;
 object-fit: cover;
 }

 &::before {
 // Spots behind the header
 content: "";
 position: absolute;
 bottom: -4rem;
 left: 70%;
 width: 27%;
 height: 4rem;

 @include bg-spot;
 background-color: var(--bs-primary-100);
 }

 &::after {
 // Colour overlaid on the image
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: var(--bs-primary-700);
 opacity: 0.9;
 
 z-index: 1;
 }

 .page-heading-decor {
 // <div> whose pseudoelements are used for the patterned blocks inside the header component
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 
 z-index: 2;

 &::before {
 // Striped circle
 content: "";
 position: absolute;
 top: 0;
 left: 10%;
 width: 10rem;
 height: 10rem;

 @include bg-stripe;
 background-color: var(--bs-primary-100);
 border-radius: 100%;
 }

 &::after {
 // Spotty rectangle
 content: "";
 position: absolute;
 top: 15%;
 left: 20%;
 width: 70%;
 height: 9rem;
 opacity: 0.9;

 @include bg-spot;
 background-color: var(--bs-white);
 }
 }
}

.msl-breadcrumb {
 padding: 0 0 0.5rem 0 !important;
 text-align: left !important;
 
 .msl-breadcrumb-separator {
 margin: 0 3px !important;
 }
}