// Define brand colours
$blue: #00AEEF;
$yellow: #EEB338;
$crimson: #D91B5B;
$gray: #3D3937;

// Gray is the default primary.
$primary: $gray;

// Add crimson and gray gradients.
$crimson-100: tint-color($crimson, 80%);
$crimson-200: tint-color($crimson, 60%);
$crimson-300: tint-color($crimson, 40%);
$crimson-400: tint-color($crimson, 20%);
$crimson-500: $crimson;
$crimson-600: shade-color($crimson, 20%);
$crimson-700: shade-color($crimson, 40%);
$crimson-800: shade-color($crimson, 60%);
$crimson-900: shade-color($crimson, 80%);

$gray-100: tint-color($gray, 80%);
$gray-200: tint-color($gray, 60%);
$gray-300: tint-color($gray, 40%);
$gray-400: tint-color($gray, 20%);
$gray-500: $gray;
$gray-600: shade-color($gray, 20%);
$gray-700: shade-color($gray, 40%);
$gray-800: shade-color($gray, 60%);
$gray-900: shade-color($gray, 80%);

$crimsons: (
 "crimson-100": $crimson-100,
 "crimson-200": $crimson-200,
 "crimson-300": $crimson-300,
 "crimson-400": $crimson-400,
 "crimson-500": $crimson-500,
 "crimson-600": $crimson-600,
 "crimson-700": $crimson-700,
 "crimson-800": $crimson-800,
 "crimson-900": $crimson-900
);

$grays: (
 "gray-100": $gray-100,
 "gray-200": $gray-200,
 "gray-300": $gray-300,
 "gray-400": $gray-400,
 "gray-500": $gray-500,
 "gray-600": $gray-600,
 "gray-700": $gray-700,
 "gray-800": $gray-800,
 "gray-900": $gray-900
);

$primaries: (
 "primary-100": var(--bs-primary-100),
 "primary-200": var(--bs-primary-200),
 "primary-300": var(--bs-primary-300),
 "primary-400": var(--bs-primary-400),
 "primary-500": var(--bs-primary-500),
 "primary-600": var(--bs-primary-600),
 "primary-700": var(--bs-primary-700),
 "primary-800": var(--bs-primary-800),
 "primary-900": var(--bs-primary-900),
);

// Make standard Bootstrap controls available in all our brand colours.
$custom-colors: (
 "crimson": $crimson,
 "gray": $gray,
);

@import "bootstrap/variables";
@import "bootstrap/maps";
@import "bootstrap/mixins";

$theme-colors: map-merge($theme-colors, $custom-colors);

// Generate background utility classes for all our brand colours.
$utilities-bg-colors: map-merge-multiple($blues, $yellows, $crimsons, $grays, $primaries);

:root {
 @each $color, $value in $blues {
 --#{$variable-prefix}#{$color}: #{$value};
 }

 @each $color, $value in $yellows {
 --#{$variable-prefix}#{$color}: #{$value};
 }

 @each $color, $value in $crimsons {
 --#{$variable-prefix}#{$color}: #{$value};
 }

 @each $color, $value in $grays {
 --#{$variable-prefix}#{$color}: #{$value};
 }
 
 // Gray is default
 --bs-primary: #{$gray};
 --bs-primary-100: #{$gray-100};
 --bs-primary-200: #{$gray-200};
 --bs-primary-300: #{$gray-300};
 --bs-primary-400: #{$gray-400};
 --bs-primary-500: #{$gray-500};
 --bs-primary-600: #{$gray-600};
 --bs-primary-700: #{$gray-700};
 --bs-primary-800: #{$gray-800};
 --bs-primary-900: #{$gray-900};
 
 --bs-primary-rgb: #{to-rgb($gray)};
 --bs-primary-100-rgb: #{to-rgb($gray-100)};
 --bs-primary-200-rgb: #{to-rgb($gray-200)};
 --bs-primary-300-rgb: #{to-rgb($gray-300)};
 --bs-primary-400-rgb: #{to-rgb($gray-400)};
 --bs-primary-500-rgb: #{to-rgb($gray-500)};
 --bs-primary-600-rgb: #{to-rgb($gray-600)};
 --bs-primary-700-rgb: #{to-rgb($gray-700)};
 --bs-primary-800-rgb: #{to-rgb($gray-800)};
 --bs-primary-900-rgb: #{to-rgb($gray-900)};
 
 // We comment out Bootstrap's declaration of this in their _root.scss file and do it ourselves here
 // so we can make it follow a CSS variable.
 --bs-link-color-rgb: var(--bs-primary-700-rgb);
 --bs-link-hover-color-rgb: var(--bs-primary-900-rgb);
}

.primary-gray {
 --bs-primary: #{$gray};
 --bs-primary-100: #{$gray-100};
 --bs-primary-200: #{$gray-200};
 --bs-primary-300: #{$gray-300};
 --bs-primary-400: #{$gray-400};
 --bs-primary-500: #{$gray-500};
 --bs-primary-600: #{$gray-600};
 --bs-primary-700: #{$gray-700};
 --bs-primary-800: #{$gray-800};
 --bs-primary-900: #{$gray-900};
 
 --bs-primary-rgb: #{to-rgb($gray)};
 --bs-primary-100-rgb: #{to-rgb($gray-100)};
 --bs-primary-200-rgb: #{to-rgb($gray-200)};
 --bs-primary-300-rgb: #{to-rgb($gray-300)};
 --bs-primary-400-rgb: #{to-rgb($gray-400)};
 --bs-primary-500-rgb: #{to-rgb($gray-500)};
 --bs-primary-600-rgb: #{to-rgb($gray-600)};
 --bs-primary-700-rgb: #{to-rgb($gray-700)};
 --bs-primary-800-rgb: #{to-rgb($gray-800)};
 --bs-primary-900-rgb: #{to-rgb($gray-900)};
 
 // We comment out Bootstrap's declaration of this in their _root.scss file and do it ourselves here
 // so we can make it follow a CSS variable.
 --bs-link-color-rgb: var(--bs-primary-700-rgb);
 --bs-link-hover-color-rgb: var(--bs-primary-900-rgb);
}

.primary-blue, .page_spaces, .page_events, .page_ents, .page_homepage-old, .page_root {
 --bs-primary: #{$blue};
 --bs-primary-100: #{$blue-100};
 --bs-primary-200: #{$blue-200};
 --bs-primary-300: #{$blue-300};
 --bs-primary-400: #{$blue-400};
 --bs-primary-500: #{$blue-500};
 --bs-primary-600: #{$blue-600};
 --bs-primary-700: #{$blue-700};
 --bs-primary-800: #{$blue-800};
 --bs-primary-900: #{$blue-900};
 
 --bs-primary-rgb: #{to-rgb($blue)};
 --bs-primary-100-rgb: #{to-rgb($blue-100)};
 --bs-primary-200-rgb: #{to-rgb($blue-200)};
 --bs-primary-300-rgb: #{to-rgb($blue-300)};
 --bs-primary-400-rgb: #{to-rgb($blue-400)};
 --bs-primary-500-rgb: #{to-rgb($blue-500)};
 --bs-primary-600-rgb: #{to-rgb($blue-600)};
 --bs-primary-700-rgb: #{to-rgb($blue-700)};
 --bs-primary-800-rgb: #{to-rgb($blue-800)};
 --bs-primary-900-rgb: #{to-rgb($blue-900)};
 
 // We comment out Bootstrap's declaration of this in their _root.scss file and do it ourselves here
 // so we can make it follow a CSS variable.
 --bs-link-color-rgb: var(--bs-primary-700-rgb);
 --bs-link-hover-color-rgb: var(--bs-primary-900-rgb);
}

.primary-yellow, .page_representation, .page_support {
 --bs-primary: #{$yellow};
 --bs-primary-100: #{$yellow-100};
 --bs-primary-200: #{$yellow-200};
 --bs-primary-300: #{$yellow-300};
 --bs-primary-400: #{$yellow-400};
 --bs-primary-500: #{$yellow-500};
 --bs-primary-600: #{$yellow-600};
 --bs-primary-700: #{$yellow-700};
 --bs-primary-800: #{$yellow-800};
 --bs-primary-900: #{$yellow-900};
 
 --bs-primary-rgb: #{to-rgb($yellow)};
 --bs-primary-100-rgb: #{to-rgb($yellow-100)};
 --bs-primary-200-rgb: #{to-rgb($yellow-200)};
 --bs-primary-300-rgb: #{to-rgb($yellow-300)};
 --bs-primary-400-rgb: #{to-rgb($yellow-400)};
 --bs-primary-500-rgb: #{to-rgb($yellow-500)};
 --bs-primary-600-rgb: #{to-rgb($yellow-600)};
 --bs-primary-700-rgb: #{to-rgb($yellow-700)};
 --bs-primary-800-rgb: #{to-rgb($yellow-800)};
 --bs-primary-900-rgb: #{to-rgb($yellow-900)};
 
 // We comment out Bootstrap's declaration of this in their _root.scss file and do it ourselves here
 // so we can make it follow a CSS variable.
 --bs-link-color-rgb: var(--bs-primary-700-rgb);
 --bs-link-hover-color-rgb: var(--bs-primary-900-rgb);
}

.primary-crimson, .page_activities {
 --bs-primary: #{$crimson};
 --bs-primary-100: #{$crimson-100};
 --bs-primary-200: #{$crimson-200};
 --bs-primary-300: #{$crimson-300};
 --bs-primary-400: #{$crimson-400};
 --bs-primary-500: #{$crimson-500};
 --bs-primary-600: #{$crimson-600};
 --bs-primary-700: #{$crimson-700};
 --bs-primary-800: #{$crimson-800};
 --bs-primary-900: #{$crimson-900};
 
 --bs-primary-rgb: #{to-rgb($crimson)};
 --bs-primary-100-rgb: #{to-rgb($crimson-100)};
 --bs-primary-200-rgb: #{to-rgb($crimson-200)};
 --bs-primary-300-rgb: #{to-rgb($crimson-300)};
 --bs-primary-400-rgb: #{to-rgb($crimson-400)};
 --bs-primary-500-rgb: #{to-rgb($crimson-500)};
 --bs-primary-600-rgb: #{to-rgb($crimson-600)};
 --bs-primary-700-rgb: #{to-rgb($crimson-700)};
 --bs-primary-800-rgb: #{to-rgb($crimson-800)};
 --bs-primary-900-rgb: #{to-rgb($crimson-900)};
 
 // We comment out Bootstrap's declaration of this in their _root.scss file and do it ourselves here
 // so we can make it follow a CSS variable.
 --bs-link-color-rgb: var(--bs-primary-700-rgb);
 --bs-link-hover-color-rgb: var(--bs-primary-900-rgb);
}

// Override Bootstrap's use of Sass variables with CSS variables, so they can be changed easily
// in the CMS/frontend by loading another stylesheet. This is how we can allow different pages
// to have different colour schemes.
$mark-bg: var(--bs-primary-100);
$link-color: var(--bs-primary-700);
$link-hover-color: var(--bs-primary-900);
$hr-color: var(--bs-primary);
$body-bg: #FCFCFC;