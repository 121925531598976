details {
 padding: 10px 16px;
 margin-bottom: 1rem;
 
 summary {
 background-color: var(--bs-primary-100);
 padding: 10px 16px;
 margin: -10px -16px;
 border-radius: $border-radius;
 }
 
 &[open] {
 border-left: 0.5px solid var(--bs-primary);
 border-top-left-radius: $border-radius;
 
 & summary {
 margin-bottom: 7px;
 }
 }
 
 & *:last-child {
 margin-bottom: 0;
 }
}