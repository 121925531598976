@mixin button-styles {
 // Core button styles
 background: $body-bg;
 color: $body-color;
 margin: 7px 7px 7px 0;
 padding: 9px 15px;
 text-decoration: none;
 font-size: inherit;
 line-height: 1.2;
 white-space: nowrap;
 display: inline-block;
 width: fit-content;
 font-weight: normal;
 cursor: pointer;
 transition: all 0.1s;
 border-radius: $border-radius;
 
 // Button hover and active states
 &:hover {
 transform: scale(103%);
 }
 
 &:active {
 transform: scale(97%);
 }
}

@mixin primary-buttons {
 border: 2px solid var(--bs-primary);
 
 &:hover {
 border-color: var(--bs-primary-400);
 }
 
 &:active {
 background-color: var(--bs-primary-100);
 border-color: var(--bs-primary-600);
 }
}

a.button, button, input[type="submit"], input[type="reset"], input[type="button"], .msl-sso-login-link {
 @include button-styles;
 
 // Button sizes
 &.button-small {
 font-size: 0.8rem;
 padding: 5px 12px;
 }
 
 &.button-large, .msl-sso-login-link {
 font-size: 1.2rem;
 padding: 10px 18px;
 }
 
 // Button colours
 // Primary buttons.
 &:not(.button-secondary), .msl-sso-login-link {
 @include primary-buttons;
 }
 
 // Secondary buttons
 &.button-secondary {
 border: 1.5px solid var(--bs-primary-400);
 font-weight: 100;
 
 &:hover {
 border-color: var(--bs-primary-400);
 }
 
 &:active {
 background-color: var(--bs-primary-100);
 border-color: var(--bs-primary-300);
 }
 }
 
 &.button-plain {
 border: 0;
 padding: 1px;
 margin: 1px;
 text-decoration: underline;
 
 &:hover {
 transform: scale(100%);
 }
 
 &:active {
 transform: scale(100%);
 background-color: inherit;
 }
 }
}